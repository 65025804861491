import Service from "./config/ConfigRepositoryAuth";

export default {
  async getAllMovil() {
    return await Service.get(`purchases_movil`);
  },
  async purchaseMovil(purchase) {
    return await Service.post(`purchases_movil`, purchase);
  },
  async purchaseHomePlan(payment) {
    return await Service.post(`purchases_home`, payment);
  },
  async purchaseUpdateHomePlan(payment) {
    return await Service.put(`purchases_home`, payment);
  },
  async paymentInternetPlan(id) {
    return await Service.put(`purchases/${id}/pay`);
  }
};
