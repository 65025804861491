import PurchaseRepository from "../repository/Purchase.repository";

export default class Purchase {
  static async getAllMovil() {
    return new Promise((resolve, reject) => {
      PurchaseRepository.getAllMovil()
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  static async purchaseMovil(purchase) {
    return new Promise((resolve, reject) => {
      PurchaseRepository.purchaseMovil(purchase)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.warn(error);
        });
    });
  }

  static async purchaseHomePlan(payment) {
    return new Promise((resolve, reject) => {
      PurchaseRepository.purchaseHomePlan(payment)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.warn(error);
        });
    });
  }

  static async purchaseUpdateHomePlan(payment) {
    return new Promise((resolve, reject) => {
      PurchaseRepository.purchaseUpdateHomePlan(payment)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.warn(error);
        });
    });
  }
  static async purchaseInternetPlan(id) {
    return new Promise((resolve, reject) => {
      PurchaseRepository.paymentInternetPlan(id)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.warn(error);
        });
    });
  }
}
